<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 245.41 244.91"
    :width="width"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-2"
          d="M166.1,0l0,.35c-.71,0-1.42.08-2.14.08H92.88c-3.71,0-7.42.05-11.13.07l0-.5Z"
        />
        <path class="cls-3" d="M245,78.29l.37,0v7.27l-.37,0Z" />
        <path
          class="cls-4"
          d="M23.85,220.74A78,78,0,0,1,5.46,192.56a79.76,79.76,0,0,1-5.4-29.18C0,137,.08,110.64,0,84.28c0-2.12.61-2.55,2.63-2.55q38.22.1,76.45,0c.86,0,1.72,0,2.57,0a.48.48,0,0,0,.15.2c0,8-.07,16.1-.07,24.15q0,28.65,0,57.3c-.64.56-1.31,1.08-1.91,1.68q-27.06,27-54.11,54C25.16,219.65,24.49,220.18,23.85,220.74Z"
        />
        <path
          class="cls-5"
          d="M81.75.54c3.71,0,7.42-.07,11.13-.07H164c.72,0,1.43,0,2.14-.08a80.85,80.85,0,0,1,53.51,22.4c.51.48,1,1,1.43,1.57-.49.41-1,.78-1.46,1.23Q192.18,53,164.76,80.33a3.35,3.35,0,0,0-1.29,1.9c-.8-.68-1.77-.41-2.68-.41q-38.31,0-76.64,0c-.78,0-1.57.08-2.35.12a.48.48,0,0,1-.15-.2c0-6.77.06-13.53.06-20.3Q81.74,31,81.75.54Z"
        />
        <path
          class="cls-6"
          d="M23.85,220.74c.64-.56,1.31-1.09,1.91-1.68q27.06-27,54.11-54c.6-.6,1.27-1.12,1.91-1.68h81.56a.52.52,0,0,0,.19.11c-.32,12.69-2.78,24.9-8.69,36.2C141.69,224.84,121,240.09,92.93,244c-26.3,3.68-49.28-4.28-68.55-22.64A6.81,6.81,0,0,1,23.85,220.74Z"
        />
        <path
          class="cls-7"
          d="M163.47,82.23a3.35,3.35,0,0,1,1.29-1.9Q192.18,53,219.61,25.59c.45-.45,1-.82,1.46-1.23,8.57,8,14.53,17.78,18.73,28.64A77.34,77.34,0,0,1,245,78.29V85.6a81.54,81.54,0,0,1-65.36,76.18,96.9,96.9,0,0,1-16.1,1.72.52.52,0,0,1-.19-.11c0-.92.11-1.84.12-2.76Q163.48,121.44,163.47,82.23Z"
        />
        <path
          class="cls-8"
          d="M163.47,82.23q0,39.19,0,78.4c0,.92-.08,1.84-.12,2.76H81.78q0-28.65,0-57.3c0-8,0-16.1.07-24.15.78,0,1.57-.12,2.35-.12q38.32,0,76.64,0C161.7,81.82,162.67,81.55,163.47,82.23Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: ["width"],
};
</script>

<style>
.cls-1 {
  fill: #fefeff;
}
.cls-2 {
  fill: #c1e5ed;
}
.cls-3 {
  fill: #d0edfa;
}
.cls-4 {
  fill: #97bd3e;
}
.cls-5 {
  fill: #0796b8;
}
.cls-6 {
  fill: #b9d53a;
}
.cls-7 {
  fill: #42c2f1;
}
.cls-8 {
  fill: #113848;
}
</style>