var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header"}},[_c('v-app-bar',{staticStyle:{"backdrop-filter":"blur(10px)"},attrs:{"color":_vm.$vuetify.theme.dark ? '#222222C7' : '#FFFFFFCB',"prominent":"","shrink-on-scroll":"","app":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.menu != null)?_c('v-tabs',{attrs:{"show-arrows":"","centered":""}},[_c('v-tab',{attrs:{"to":'/' + _vm.section.sectionSlug + '/home'}},[_vm._v(_vm._s(_vm.$t('home')))]),_c('v-tab',{attrs:{"to":'/' + _vm.section.sectionSlug + '/staff'}},[_vm._v(_vm._s(_vm.$t('staff')))]),_c('v-tab',{attrs:{"to":'/' + _vm.section.sectionSlug + '/lessons'}},[_vm._v(_vm._s(_vm.$t('lessons')))]),_vm._l((_vm.menuChild(0)),function(link){return _c('v-tab',{key:link.idMenuContent,attrs:{"href":link.isExternalLink == 1
              ? link.externalLink
              : '/' + _vm.section.sectionSlug + '/page/' + link.pageId}},[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$locale == "en" ? link.titleEn : link.title)+" "),(_vm.menuChild(link.idMenuContent).length > 0)?_c('v-icon',{attrs:{"left":"","size":"12"}},[_vm._v("la-angle-down")]):_vm._e()],1)]}}],null,true)},[(_vm.menuChild(link.idMenuContent).length > 0)?_c('v-list',_vm._l((_vm.menuChild(link.idMenuContent)),function(item){return _c('v-list-item',{key:item.idMenuContent,attrs:{"href":item.isExternalLink == 1
                    ? item.externalLink
                    : '/' + _vm.section.sectionSlug + '/page/' + item.pageId}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$locale == "en" ? item.titleEn : item.title))])],1)}),1):_vm._e()],1)],1)})],2):_vm._e()]},proxy:true}])},[(_vm.$vuetify.theme.dark)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.darkMode()}}},[_c('v-icon',[_vm._v("la-sun")])],1):_vm._e(),(!_vm.$vuetify.theme.dark)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.darkMode()}}},[_c('v-icon',[_vm._v("la-moon")])],1):_vm._e(),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-translate")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setLocale('ar')}}},[_c('v-list-item-title',[_vm._v("العربية")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setLocale('en')}}},[_c('v-list-item-title',[_vm._v("English")])],1)],1)],1),_c('v-spacer'),_c('img',{staticStyle:{"transition":"300ms","position":"fixed","left":"50%","transform":"translateX(-50%)"},attrs:{"src":_vm.$baseUrl + _vm.section.sectionLogo,"alt":"","height":_vm.logoHeight}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("la-youtube")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("la-instagram")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("la-twitter")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("la-facebook")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }